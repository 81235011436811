var React = require("react");
var ReactDOM = require('react-dom');
var $ = require('jquery');
var slugify = require('./helpers/slugify');
var _ = require('underscore');

//React.initializeTouchEvents(true);

// Stylesheets
//require("./style/common.less");
require("./style/style.less");

// Stores
var LanguageStore = require('./stores/language_store');

// Components
var EnvHeader = require('./components/env_header');
var JobList = require('./components/job_list');
var DisabledView = require('./components/disabled_view');
var PoweredBy = require('./components/powered_by');
var Navigation = require('./components/navigation');
var language = JSON.parse($("input[name='language']").val());
LanguageStore.load(language);

// Job data
var job_data = $("input[name='jobs_data']").val();
	job_data = (job_data != null && job_data != '' && job_data != 'null') ? JSON.parse(job_data) : null;

// Env info	
var env_info = $("input[name='env_info']").val();
	env_info = (env_info != null && env_info != '' && env_info != 'null') ? JSON.parse(env_info) : null;

// Env name
var env_name = $("input[name='env']").val();

// Selected category
var selected_category = $("input[name='selected_category']").val();

var jobs = job_data.jobs;
var jobs_per_category = [];

_.each(jobs, function(job){
	_.each(job.categories, function(category){
		var c = _.findWhere(jobs_per_category, { id : slugify(category)});
		
		category = category.replace('&amp;', '&');
		category = category.replace('&amp;', '&');
		
		if(!c){
			jobs_per_category.push({
				id : slugify(category),
				name : category,
				job_count : 1
			});
		} else {
			c.job_count++;
		}
	});
});

//console.log(jobs_per_category)

// Trim jobs if a category is selected
if(selected_category != ''){
	var selected_jobs = [];
	
	_.each(jobs, function(job){
		var is_match = false;
		_.each(job.categories, function(category){
			
			category = category.replace('&amp;', '&');
			category = category.replace('&amp;', '&');
		
			if(selected_category === slugify(category)){
				is_match = true;
			}
			
			if(true){
				if(!_.isUndefined(env_info.settings.enable_category_groups) && env_info.settings.enable_category_groups){
					if(!_.isUndefined(env_info.settings.category_groups) && env_info.settings.category_groups.length > 0){
						_.each(env_info.settings.category_groups, function(group){
							if(selected_category === slugify(group.name)){
								if(_.findWhere(group.categories, { name : category })){
									is_match = true;
								}
							}
						});
					}
				}
			}
			
		});
		
		if(is_match){
			selected_jobs.push(job);
		}
	});
	
	jobs = selected_jobs;
}

var wrapperStyle = {};

//var body_bg_url = (env_info.settings.body_bg_id != '') ? '/bg/'+env_name+'/'+env_info.settings.body_bg_id : '';
var body_bg_url = (env_info.settings.body_bg_id != '') ? 'https://images.searchsoftware.nl/'+env_name+'/bg/'+env_info.settings.body_bg_id : '';
		
if(body_bg_url != ''){
 	//wrapperStyle.backgroundImage = 'url('+body_bg_url+')';
 	//$('body').css('background', 'url('+body_bg_url+') no-repeat center center fixed');
 	
 	// Desktop
 	if($(window).width() > 600){
 		$('body').css('background', 'url('+body_bg_url+') no-repeat center center fixed');
 		$('body').css('background-size', ($(window).width()+200)+'px');
 		
 	} else {
	 	//$('body').css('background', 'url('+body_bg_url+') center no-repeat');
	 	//$('body').find('.env-header').css('background-size', ($(window).width())+'px');
 	}
}

// Colors
function hexToRgb(hex) {
    var bigint = parseInt(hex, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;

    return r + "," + g + "," + b;
}
	
var customStyle = $('<style></style>');

// Load font
if(typeof env_info.default_job_board_font != 'undefined' && env_info.default_job_board_font != ''){
	var font = env_info.default_job_board_font;
	
	$("<link href='https://fonts.googleapis.com/css?family="+font.replace(/ /ig, '+')+"' rel='stylesheet' type='text/css'>")
	.appendTo($('head'));
	
	customStyle.append("body { font-family:'"+font+"'; }");

// Default font
} else {
	var font = 'Tinos';
	
	$("<link href='https://fonts.googleapis.com/css?family="+font+"' rel='stylesheet' type='text/css'>")
	.appendTo($('head'));
	
	customStyle.append("body { font-family:'"+font+"'; }");
}
console.log(env_info);
if(typeof env_info.colors != 'undefined'){
	_.each(env_info.colors, function(color, key){
		//console.log(color, key);
		
		// Header text color
		if(key === 'header_text_color'){
			customStyle.append(".env-header h1 { color:"+color+"; }");
		}
		
		// Navigation bar
		if(key === 'nav_bar_background_color'){
			
			if((typeof env_info.colors.nav_bar_background_transparency != 'undefined')){
				var rgb = hexToRgb(color.replace('#', ''));
				var transparency = parseInt(env_info.colors.nav_bar_background_transparency.replace('%', '')) / 100;
				
				customStyle.append(".navigation { background:rgba("+rgb+", "+transparency+"); }");
			} else {
				customStyle.append(".navigation { background:"+color+"; }");
			}				
				
			customStyle.append(".navigation .content .category-list { background:"+color+"; }");
		}
			
		// Navigation bar current-category
		if(key === 'nav_bar_categories_background'){
			customStyle.append(".navigation .content .current-category { background:"+color+"; }");
		}

		if(key === 'nav_bar_categories_background_hover'){
			customStyle.append(".navigation .content .current-category:hover { background:"+color+"; }");
			customStyle.append(".navigation .content .category-list li a:hover { background:"+color+"; }");
		}
			
		if(key === 'nav_bar_categories_text_color'){
			customStyle.append(".navigation .content .current-category { color:"+color+"; }");
		}
			
		if(key === 'nav_bar_categories_text_color_hover'){
			customStyle.append(".navigation .content .current-category:hover { color:"+color+"; }");
			customStyle.append(".navigation .content .category-list li a:hover { color:"+color+"; }");
		}
		
		// Navigation bar home-button
		if(key === 'nav_bar_home_button_background'){
			customStyle.append(".navigation .content .website-home-button { background:"+color+"; }");
		}
			
		if(key === 'nav_bar_home_button_text_color'){
			customStyle.append(".navigation .content .website-home-button { color:"+color+"; }");
		}
			
		if(key === 'nav_bar_home_button_background_hover'){
			customStyle.append(".navigation .content .website-home-button:hover { background:"+color+"; }");
		}
			
		if(key === 'nav_bar_home_button_text_color_hover'){
			customStyle.append(".navigation .content .website-home-button:hover { color:"+color+"; }");
		}
			
		// Navigation bar apply-button
		if(key === 'nav_bar_apply_button_background'){
			customStyle.append(".navigation .content .website-apply-button { background:"+color+"; }");
		}
			
		if(key === 'nav_bar_apply_button_text_color'){
			customStyle.append(".navigation .content .website-apply-button { color:"+color+"; }");
		}
			
		if(key === 'nav_bar_apply_button_background_hover'){
			customStyle.append(".navigation .content .website-apply-button:hover { background:"+color+"; }");
		}
			
		if(key === 'nav_bar_apply_button_text_color_hover'){
			customStyle.append(".navigation .content .website-apply-button:hover { color:"+color+"; }");
		}
			
		// Navigation bar subscribe-button
		if(key === 'nav_bar_subscribe_button_background'){
			customStyle.append(".navigation .content .subscribe-button { background:"+color+"; }");
		}
			
		if(key === 'nav_bar_subscribe_button_text_color'){
			customStyle.append(".navigation .content .subscribe-button { color:"+color+"; }");
		}
			
		if(key === 'nav_bar_subscribe_button_background_hover'){
			customStyle.append(".navigation .content .subscribe-button:hover { background:"+color+"; }");
		}
			
		if(key === 'nav_bar_subscribe_button_text_color_hover'){
			customStyle.append(".navigation .content .subscribe-button:hover { color:"+color+"; }");
		}
		
		// Apply button
		if(key === 'job_view_apply_button_text_color'){
			customStyle.append(".job-list-container table tr td .job-list .job-item .job-apply-button { color:"+color+"; }");
			customStyle.append(".job-list-container table tr td .job-list .job-item:hover .job-apply-button { color:"+color+"; }");
		}
		
		if(key === 'job_view_apply_button_text_color_hover'){
			customStyle.append(".job-list-container table tr td .job-list .job-item .job-apply-button:hover { color:"+color+"; }");
			customStyle.append(".job-list-container table tr td .job-list .job-item:hover .job-apply-button:hover { color:"+color+"; }");
		}
		
		if(key === 'job_view_apply_button_background'){
			customStyle.append(".job-list-container table tr td .job-list .job-item .job-apply-button { background:"+color+"; }");
			customStyle.append(".job-list-container table tr td .job-list .job-item:hover .job-apply-button { background:"+color+"; }");
		}
		
		if(key === 'job_view_apply_button_background_hover'){
			customStyle.append(".job-list-container table tr td .job-list .job-item .job-apply-button:hover { background:"+color+"; }");
			customStyle.append(".job-list-container table tr td .job-list .job-item:hover .job-apply-button:hover { background:"+color+"; }");
		}
		
		if(key === 'job_view_apply_button_border'){
			customStyle.append(".job-list-container table tr td .job-list .job-item .job-apply-button { border:1px solid "+color+"; }");
			customStyle.append(".job-list-container table tr td .job-list .job-item:hover .job-apply-button { border:1px solid "+color+"; }");
		}
		
		if(key === 'job_view_apply_button_border_hover'){
			customStyle.append(".job-list-container table tr td .job-list .job-item .job-apply-button:hover { border:1px solid "+color+"; }");
			customStyle.append(".job-list-container table tr td .job-list .job-item:hover .job-apply-button:hover { border:1px solid "+color+"; }");
		}
		
		// HIGHLIGHTED Apply button
		if(key === 'highlighted_apply_button_text_color'){
			customStyle.append(".job-list-container table tr td .job-list .job-item .job-apply-button.highlighted { color:"+color+"; }");
			customStyle.append(".job-list-container table tr td .job-list .job-item:hover .job-apply-button.highlighted { color:"+color+"; }");
		}
		
		if(key === 'highlighted_apply_button_text_color_hover'){
			customStyle.append(".job-list-container table tr td .job-list .job-item .job-apply-button.highlighted:hover { color:"+color+"; }");
			customStyle.append(".job-list-container table tr td .job-list .job-item:hover .job-apply-button.highlighted:hover { color:"+color+"; }");
		}
		
		if(key === 'highlighted_apply_button_background'){
			customStyle.append(".job-list-container table tr td .job-list .job-item .job-apply-button.highlighted { background:"+color+"; }");
			customStyle.append(".job-list-container table tr td .job-list .job-item:hover .job-apply-button.highlighted { background:"+color+"; }");
		}
		
		if(key === 'highlighted_apply_button_background_hover'){
			customStyle.append(".job-list-container table tr td .job-list .job-item .job-apply-button.highlighted:hover { background:"+color+"; }");
			customStyle.append(".job-list-container table tr td .job-list .job-item:hover .job-apply-button.highlighted:hover { background:"+color+"; }");
		}
		
		if(key === 'highlighted_apply_button_border'){
			customStyle.append(".job-list-container table tr td .job-list .job-item .job-apply-button.highlighted { border:1px solid "+color+"; }");
			customStyle.append(".job-list-container table tr td .job-list .job-item:hover .job-apply-button.highlighted { border:1px solid "+color+"; }");
		}
		
		if(key === 'highlighted_apply_button_border_hover'){
			customStyle.append(".job-list-container table tr td .job-list .job-item .job-apply-button.highlighted:hover { border:1px solid "+color+"; }");
			customStyle.append(".job-list-container table tr td .job-list .job-item:hover .job-apply-button.highlighted:hover { border:1px solid "+color+"; }");
		}
	});
}
	
customStyle.appendTo('head');
	
// App
var App = React.createClass({
	render: function(){
		
		// Enabled		
		if(env_info.settings.enable_job_board){
			return (
				<div className="wrapper" style={wrapperStyle}>	
					
					<EnvHeader env={env_name} env_info={env_info} />
					
					<Navigation env_info={env_info} total_jobs={job_data.jobs.length} env={env_name} selected_category={selected_category} categories={jobs_per_category} />
					
					<JobList env={env_name} env_info={env_info} jobs={jobs} />
					
					<PoweredBy />
					
				</div>
			);
		
		// Disabled
		} else {
			return (
				<div className="wrapper">	
					
					<DisabledView />
					
				</div>
			);
		}	
	}
});

// Render app to body
ReactDOM.render(<App />, document.body);
var React = require("react");
var ReactDOM = require("react-dom");
var $ = require('jquery');
var Reflux = require('reflux');

// Stores
var LanguageStore = require('../stores/language_store');

// Job Area
module.exports = React.createClass({
	
	
	// Render
  	render: function(){
  		
  		var style = {};
  		
  		var url = '/'+this.props.env;
  		
  		if(typeof this.props.env_info.custom_job_board_domain != 'undefined' && this.props.env_info.custom_job_board_domain != ''){
  			url = '/';
  		}
  		
  		//var header_bg_url = (this.props.env_info.settings.header_bg_id != '') ? '/bg/'+this.props.env+'/'+this.props.env_info.settings.header_bg_id : '';
  		
  		var header_bg_url = 'https://images.searchsoftware.nl/'+this.props.env+'/bg/'+this.props.env_info.settings.header_bg_id;
  		
  		if(header_bg_url != ''){
	  		style.backgroundImage = 'url('+header_bg_url+')';
	  		style.backgroundSize = $(window).width();
  		}
  		
  		//var title = 'Jobs at '+this.props.env_info.title;
  		var title = '';
  		
  		var custom_title = LanguageStore.get('job_board/title/jobs_at');
  		
  		if(custom_title != ''){
	  		title = custom_title;
  		}
  		
  		
  		if($(window).width() < 600){
	  		//style.backgroundSize = '600px';
  		}
  		
  		var logo_img = <a href={url}><img src={this.props.env_info.logo} /></a>;
  		
  		if(this.props.env === 'vel' || this.props.env === 'bro' || this.props.env_info.hide_environment_logo === '1'){
	  		logo_img = '';
  		}
  		
  		if(this.props.env_info.hide_environment_logo === '1' || this.props.env_info.hide_environment_logo === 1){
	  		logo_img = '';
	  		style.height = '240px';
  		}
  		
  		if(this.props.env === 'bro'){
	  		style.height = '240px';
  		}
  		
  		if(this.props.env === 'gmensen'){
	  		style.height = '240px';
  		}
  		
  		if(this.props.env === 'htnk'){
	  		style.height = '280px';
  		}
  		
  		if(this.props.env === 'spring'){
	  		style.height = '200px';
  		}
  		
  		if(this.props.env === 'demo'){
  			logo_img = '';
	  		style.height = '300px';
  		}
  		
  		if(this.props.env === 'apnet'){
  			logo_img = '';
	  		style.height = '190px';
  		}
  		
  		if(this.props.env === 'taskhero'){
  			logo_img = '';
	  		style.height = '180px';
  		}
  		
  		if(this.props.env === 'p2w'){
  			logo_img = '';
	  		style.height = '74px';
  		}
  		
  		if(this.props.env === 'akira'){
	  		style.background = '#fff';
	  		logo_img = (<a href={url}><img src={this.props.env_info.logo} style={{ height:'200px' }} /></a>);
  		}
  		
  		/*if(this.props.env === 'werck'){
  			logo_img = '';
	  		style.height = '330px';
  		}*/
  		
  		if($(window).width() < 600){
	  		if(this.props.env === 'gmensen'){
	  			style.height = '80px';
	  		}
	  		
	  		if(this.props.env === 'htnk'){
	  			style.height = '80px';
	  		}
  		}
  		
  	   	return (<div className="env-header" style={style}>
   			
   			{logo_img}
   			
   			<h1>{title}</h1>
	  	</div>);
	}
});

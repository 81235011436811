var React = require("react");
var ReactDOM = require("react-dom");
var $ = require('jquery');
var Reflux = require('reflux');

// Job Area
module.exports = React.createClass({
	
	
	// Render
  	render: function(){
  		
  		var url = '/'+this.props.env;
  		
  	   	return (<div className="disabled-view">
   			<div className="content">Job board is unavailable...</div>
	  	</div>);
	}
});

var React = require("react");
var ReactDOM = require("react-dom");
var $ = require('jquery');
var Reflux = require('reflux');
var _ = require('underscore');

// Stores
var LanguageStore = require('../stores/language_store');

// Helper
var slugify = require('../helpers/slugify');

// Job Area
module.exports = React.createClass({
	
	getInitialState : function(){
		return {
			show_category_list : false
		}
	},
	
	toggleCategoryList : function(e){
		
		e.preventDefault();
		e.stopPropagation();
		
		if(this.state.show_category_list){
			this.setState({ show_category_list : false });
		} else {
			this.setState({ show_category_list : true });
		}
	},
	
	// Render
  	render: function(){
  		
  		var self = this;
  		var target = '_blank';
  		var env_info = self.props.env_info;
  		
  		if(typeof this.props.env_info.settings.job_links_target != 'undefined' && this.props.env_info.settings.job_links_target === 'same_tab'){
  			target = '_self';
  		}
  		
  		var sorted_categories = _.sortBy(this.props.categories, (c) => {
	  		return c.name.toLowerCase();
  		});
  		
  		var categories = sorted_categories.map(function(c, index){
  			
  			var url = '/'+self.props.env+'/'+c.id;
  			var name = c.name.replace(/&amp;/ig, '&');
  			
  			if(typeof self.props.env_info.custom_job_board_domain != 'undefined' && self.props.env_info.custom_job_board_domain != ''){
  				var custom_domain = self.props.env_info.custom_job_board_domain;
  				url = 'http://'+custom_domain+'/'+c.id;
  			}
  			
		    return (<li>
		    	<a href={url}>
		    		{name}
		    		<span className="counter">{c.job_count}</span>
		    	</a>
		    	
		    	<div className="clear"></div>
		    </li>);
		});
		
		var current_category = '';
		
		if(this.props.selected_category === ''){
			current_category = LanguageStore.get('job_board/link/browse_by_category');
		} else {
			
			var url = '/'+self.props.env;
			
			if(typeof self.props.env_info.custom_job_board_domain != 'undefined' && self.props.env_info.custom_job_board_domain != ''){
  				var custom_domain = self.props.env_info.custom_job_board_domain;
  				url = 'http://'+custom_domain;
  			}
  			
			var el = (<li>
		    	<a href={url}>
		    		All
		    		<span className="counter">{this.props.total_jobs}</span>
		    	</a>
		    	
		    	<div className="clear"></div>
		    </li>);
		    
			categories = [el].concat(categories);
			var cur_cat = _.findWhere(this.props.categories, { id : this.props.selected_category});
			current_category = (cur_cat) ? cur_cat.name : '';
		}
		
		var categoryListClasses = "category-list";
		var dropdownIconClasses = "icon fa";
		
		if(this.state.show_category_list){
			categoryListClasses += " show";
			dropdownIconClasses += " fa-chevron-circle-up";
		} else {
			dropdownIconClasses += " fa-chevron-circle-down";
		}
		
		var website_home = '';
		
		if(this.props.env_info.website_url != '' && LanguageStore.get('job_board/link/home').trim() !== ''){
			var website_url = (this.props.env_info.website_url.indexOf('http://') === -1) ? 'http://'+this.props.env_info.website_url : this.props.env_info.website_url;
			website_home = (<a target={target} href={website_url} className="website-home-button">{LanguageStore.get('job_board/link/home')}</a>);
		}
		
		
		var open_apply_url = 'http://jobs.searchsoftware.nl/'+this.props.env+'/apply';
		
		if(typeof self.props.env_info.custom_job_board_domain != 'undefined' && self.props.env_info.custom_job_board_domain != ''){
  			var custom_domain = self.props.env_info.custom_job_board_domain;
  			open_apply_url = 'http://'+custom_domain+'/apply';
  		}
  			
		var website_apply = "";
		
		if(LanguageStore.get('job_board/navigation/link/apply').trim() !== ''){
			website_apply = (<a target={target} href={open_apply_url} className="website-apply-button">{LanguageStore.get('job_board/navigation/link/apply')}</a>);
		}
		
		var subscribe_button = "";
		
		if(LanguageStore.get('job_board/link/subscribe').trim() !== ''){
			subscribe_button = (<a className="subscribe-button" href={open_apply_url.replace('/apply', '/subscribe')}>{LanguageStore.get('job_board/link/subscribe')}</a>);
		}
		
		// Mobile buttons
		if($(window).width() < 600){
			
			if(website_home != ''){
				website_home = (<a target={target} href={website_url} className="website-home-button"><span className="icon fa fa-home"></span></a>);
			}
			
			website_apply = (<a target={target} href={open_apply_url} className="website-apply-button"><span className="icon fa fa-plus"></span></a>);
			
			subscribe_button = (<a className="subscribe-button" href={open_apply_url.replace('/apply', '/subscribe')}><span className="icon fa fa-rss"></span></a>);
		}
		
		if(LanguageStore.get('job_board/navigation/link/apply') === ''){
			website_apply = "";	
		}
		
		var login_button = '';
		
		if(LanguageStore.get('job_board/link/login').trim() !== ''){
			login_button = (<a target="_blank" href={this.props.env_info.external_profile_login_url} className="login-button">{LanguageStore.get('job_board/link/login').trim()}</a>);
		}
		
		// New category group layout
		
		var category_groups = [];
		
		if(true){
			if(!_.isUndefined(env_info.settings.enable_category_groups) && env_info.settings.enable_category_groups){
				if(!_.isUndefined(env_info.settings.category_groups) && env_info.settings.category_groups.length > 0){
					
					categoryListClasses += ' has_category_groups';
					
					categories = [];
					
					var url = '/'+self.props.env;
			
					if(typeof self.props.env_info.custom_job_board_domain != 'undefined' && self.props.env_info.custom_job_board_domain != ''){
		  				var custom_domain = self.props.env_info.custom_job_board_domain;
		  				url = 'http://'+custom_domain;
		  			}
		  			
					category_groups.push(<li className="group">
				    	<a href={url}>
				    		All
				    		<span className="counter">{self.props.total_jobs}</span>
				    	</a>
				    	
				    	<div className="clear"></div>
				    </li>);
				    
					if(current_category === ''){
						
						var g = _.filter(env_info.settings.category_groups, function(g){
							return slugify(g.name) === self.props.selected_category;
						});
						
						if(g.length === 1){
							current_category = g[0].name;
						}
					}
										
					var sorted_category_groups = _.sortBy(env_info.settings.category_groups, (item) => {
						return item.name.toLowerCase();
					});
					
					_.each(sorted_category_groups, function(group){
						
						if(_.isNumber(group)){
							return;
						}
						
						var url = '/';
						var job_count = 0;
						
						var url = '/'+self.props.env+'/'+slugify(group.name);
			  			
			  			if(typeof self.props.env_info.custom_job_board_domain != 'undefined' && self.props.env_info.custom_job_board_domain != ''){
			  				var custom_domain = self.props.env_info.custom_job_board_domain;
			  				url = 'http://'+custom_domain+'/'+slugify(group.name);
			  			}
			  			
						category_groups.push(<li className="group">
					    	<a href={url}>
					    		{group.name}
					    	</a>
					    	
					    	<div className="clear"></div>
					    </li>);
					    
					    /*var group_cats = _.sortBy(group.categories, (item) => {
							return item.name.toLowerCase();    
					    });*/
					    					    
					    var sorted_group_categories = _.sortBy(group.categories, (item) => {
						    return item.name.toLowerCase();
					    });
					    					    
					    _.each(sorted_group_categories, function(c){
					    	
					    	var j_category = _.findWhere(self.props.categories, { name : c.name });
					    	
					    	if(!j_category){
						    	j_category = _.findWhere(self.props.categories, { id : c.id });
					    	}
					    	
					    	if(j_category){
					    		
					    		var url = '/'+self.props.env+'/'+slugify(j_category.name);
					    		
					    		if(typeof self.props.env_info.custom_job_board_domain != 'undefined' && self.props.env_info.custom_job_board_domain != ''){
					  				var custom_domain = self.props.env_info.custom_job_board_domain;
					  				url = 'http://'+custom_domain+'/'+slugify(j_category.name);
					  			}
					  			
					  			
							    category_groups.push(<li className="category">
							    	<a href={url}>
							    		{j_category.name}
							    		<span className="counter">{j_category.job_count}</span>
							    	</a>
							    	
							    	<div className="clear"></div>
							    </li>);
						    }
					    });
					});
				}
			}
		}
		
   		return (
   			<div className="navigation">
   				
   				<div className="content">
   					
   					<div onTouchStart={this.toggleCategoryList} onClick={this.toggleCategoryList} className="current-category">
   						<span className="text">{current_category.replace(/&amp;/ig, '&')}</span>
   						<span className={dropdownIconClasses}></span>
   					</div>
   					
   					<ul className={categoryListClasses}>
   						{category_groups}
   						{categories}
   					</ul>
   				
   					{website_home}
   					
   					{website_apply}
   					
   					{subscribe_button}
   					
   					{login_button}
   						
   					<div className="clear"></div>
   				</div>
   				
	  		</div>
	  	);
	}
});

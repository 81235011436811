var React = require("react");
var ReactDOM = require("react-dom");
var $ = require('jquery');
var Reflux = require('reflux');

// Job Area
module.exports = React.createClass({
		
	// Render
  	render: function(){
  		
   		return (
   			<div className="powered-by">
   				
   				<div className="content">
	   				<a target="_blank" href="http://searchsoftware.nl?ref=apply">
	   					<img src="/assets/searchsoftware_logo.png" />
	   				</a>
	   				<div className="text">
	   					<label>Powered by</label> <a target="_blank" href="http://searchsoftware.nl?ref=job_board">Searchsoftware</a>
	   				</div>
					<div className="clear"></div>
				</div>
				
	  		</div>
	  	);
	}
});

/*** @jsx React.DOM */

// Dependencies
var Reflux = require('reflux');

// Actions

module.exports = Reflux.createStore({
        
    _language : {},
    
    load : function(lang){
    	    	
	    this._language = lang;
    },
    
    get : function(item){
	    
	    if(typeof this._language[item] != 'undefined'){
		    return this._language[item];
	    } else {
		    return '';
	    }
    }
    
});
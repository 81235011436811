var React = require("react");
var ReactDOM = require("react-dom");
var $ = require('jquery');
var Reflux = require('reflux');
var moment = require('moment');
var slugify = require('../helpers/slugify');
var _ = require('underscore');

// Stores
var LanguageStore = require('../stores/language_store');

// Job Area
module.exports = React.createClass({

	// Render
  	render: function(){
  		
  		var self = this;
  		
  		var url_title = (this.props.job.url_title != '') ? this.props.job.url_title : slugify(this.props.job.title);
  		var apply_url = 'http://jobs.searchsoftware.nl/'+this.props.env+'/'+this.props.job.job_id+'/'+url_title;
  		var form_url = 'http://jobs.searchsoftware.nl/'+this.props.env+'/apply/'+this.props.job.job_id;
  		var company_logo_url = this.props.job.company_logo+'?width=300';
  		var logo = (this.props.job.company_logo != '') ? (<img className="job-picture" src={company_logo_url} />) : '';
  		var target = '_blank';
  		
  		if(typeof this.props.env_info.settings.job_links_target != 'undefined' && this.props.env_info.settings.job_links_target === 'same_tab'){
  			target = '_self';
  		}
  		
  		// Custom domain
  		console.log('PROPS: ', this.props);
  		if(typeof this.props.env_info.custom_job_board_domain != 'undefined' && this.props.env_info.custom_job_board_domain != ''){
	  		var custom_domain = this.props.env_info.custom_job_board_domain;
	  		apply_url = 'http://'+custom_domain+'/job/'+this.props.job.job_id+'/'+url_title;
  		}
  		
  		if(this.props.job.job_url){
	  		apply_url = this.props.job.job_url;
  		}
  		
  		// custom logo
  		if(!_.isUndefined(this.props.job.publish_picture) && this.props.job.publish_picture !== ''){
	  		logo = (<img className="job-picture" src={this.props.job.publish_picture.replace('auto', 200)} />);
  		}
  		
  		if(logo == ''){
	  		logo = (<img className="job-picture" src={this.props.env_info.logo} />);
  		}
  		
  		var publish_date = this.props.job.publish_date.split(' ')[0].replace(/-/ig, '/');
  		
  		var categories = this.props.job.categories.map(function(category, index){
  			
  			var cat = category;
  				cat = cat.replace(/&amp;/ig, '&');
  				cat = slugify(cat);
  				
  			var url = '/'+self.props.env+'/'+cat;
  			
  			if(typeof self.props.env_info.custom_job_board_domain != 'undefined' && self.props.env_info.custom_job_board_domain != ''){
  				var custom_domain = self.props.env_info.custom_job_board_domain;
  				url = 'http://'+custom_domain+'/'+cat;
  			}
  			
		    return (<li><a href={url}>{category.replace(/&amp;/ig, '&')}</a></li>);
		});
		
		var job_title = this.props.job.title.replace(/&amp;/ig, '&');
		
		if(this.props.job.salary_fixed.indexOf('-') != -1){
			var salary_string = this.props.job.salary_fixed;
		} else {
			var salary_string = this.props.job.salary_fixed+' - '+this.props.job.salary_bonus;
		}
		
		salary_string = salary_string.replace(/€/ig, '');
			
		var salary = (this.props.job.salary_fixed != '' || this.props.job.salary_bonus) ? (<div className="job-salary"><span className="fa icon fa-euro"></span>{salary_string}</div>) : '';
		
		var publish_date = (<div className="job-publish-date">{LanguageStore.get('job_board/title/published')} {publish_date}</div>);
		
		if(typeof this.props.job.hide_posted_on_date != 'undefined' && (this.props.job.hide_posted_on_date === true || this.props.job.hide_posted_on_date === 'true')){
	  		publish_date = "";
  		}
  		
  		// apply_button
  		var apply_button_classes = "job-apply-button";
  		var apply_button_text = LanguageStore.get('job_board/link/apply');
  		var apply_button_icon = '';
  		
  		if(typeof this.props.job.custom_apply_text != 'undefined' && this.props.job.custom_apply_text != ''){
	  		apply_button_text = this.props.job.custom_apply_text;
	  		apply_button_classes += " highlighted";
	  		apply_button_icon = (<span className="icon fa fa-star"></span>);
  		}
  		
  		var apply_button = (<a className={apply_button_classes} target={target} href={apply_url}>
  			{apply_button_icon}
  			{apply_button_text}
  		</a>);
  		
  		var jobLocationText = this.props.job.address.trim();
  		var jobLocationStyle = {};
  		
  		if(!_.isUndefined(self.props.job.hide_address) && (self.props.job.hide_address === true || self.props.job.hide_address === 'true')){
	  		jobLocationStyle = {
		  		display : 'none'
	  		};
  		}
  		
  		// temp for indeed (they get sad if job-location tag is empty)
  		if(self.props.env === 'tdorf'){
	  		if(jobLocationText === ''){
		  		if(!_.isUndefined(self.props.job.categories) && self.props.job.categories.length === 1){
			  		jobLocationText = self.props.job.categories[0];
			  		jobLocationStyle.display = 'none';
		  		}
	  		}
  		}
  		  		
   		return (
   			<li className="job-item">
   				{logo}
   				
   				{apply_button}
   				
   				<div className="job-info">
   					<a className="job-title" target={target} href={apply_url}>{job_title}</a>
   					<div className="clear"></div>
   					<div className="job-location" style={jobLocationStyle}>{jobLocationText}</div>
   					
   					<div className="clear"></div>
   					
   					{salary}
   					
   					<div className="clear"></div>
   					
   					<ul className="job-categories">{categories}</ul>
   					
   					<div className="clear"></div>
   					
   					{publish_date}
   					
   				</div>
   				<div className="clear"></div>
	  		</li>
	  	);
	}
});

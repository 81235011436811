var React = require("react");
var ReactDOM = require("react-dom");
var $ = require('jquery');
var Reflux = require('reflux');
var _ = require('underscore');

// Components
var JobItem = require('./job_item');

// Job Area
module.exports = React.createClass({

	// Render
  	render: function(){
  		
  		var self = this;
  		var jobs = this.props.jobs;
  		var table_classes = "";
  		
  		if(typeof this.props.env_info.settings.ordered_job_list != 'undefined' && this.props.env_info.settings.ordered_job_list != ''){
	  		var ordered_jobs = [];
	  		var unordered_jobs = [];
	  		var ordered_ids = this.props.env_info.settings.ordered_job_list.split(',');
	  		
	  		_.each(ordered_ids, function(job_id){
		  		//console.log(job_id);
		  		
		  		var job = _.findWhere(jobs, { job_id : parseInt(job_id) });
		  		
		  		if(job){
			  		ordered_jobs.push(job);
		  		}
		  		
		  		var job = _.findWhere(jobs, { job_id : job_id });
		  		
		  		if(job){
			  		ordered_jobs.push(job);
		  		}
		  		
	  		});
	  		
	  		_.each(jobs, function(job){
		  		if(!_.findWhere(ordered_jobs, { job_id : job.job_id })){
			  		unordered_jobs.push(job);
		  		}
	  		});
	  		
	  		ordered_jobs = unordered_jobs.concat(ordered_jobs);
	  		
	  		jobs = ordered_jobs;
  		}
  		
  		if($(window).width() < 600){
	  		
	  		table_classes += ' mobile';
	  		
	  		// Items
			var items = jobs.map(function(job, index){
				return (<JobItem env_info={self.props.env_info} env={self.props.env} job={job} />);
			});
			
			// Columns
			var col = (<td>
		   			<ul className="job-list">{items}</ul>
		   		</td>);
			var cols = [];
				cols.push(col);

  		} else { 
	  		var columns = (typeof this.props.env_info.settings.column_count != 'undefined') ? this.props.env_info.settings.column_count : 3;
	  		
	  		var contStyle = {
		  		'margin-left' : '30px',
		  		'margin-right' : '30px'
	  		};
	  		
	  		if($(window).width() < 700){
		  		columns = 1;
	  		}
	  		
	  		if(columns == 2){
	  			contStyle = {
		  			'margin-left' : '10%',
		  			'margin-right' : '10%'
		  		};
	  		}
	  		
	  		if(columns == 1){

		  		contStyle = {
			  		'margin' : '0px auto',
			  		'width' : '600px'
		  		};
		  		
		  		if(typeof self.props.env_info.settings.job_list_position != 'undefined'){
		  			switch(self.props.env_info.settings.job_list_position){
			  			case('left'):
			  				contStyle.margin = '0px 30px 0px 30px';
			  				contStyle.float = 'left';
			  			break;
			  			
			  			case('right'):
			  				contStyle.margin = '0px 30px 0px 30px';
			  				contStyle.float = 'right';
			  			break;
		  			}	
		  		}
	  		}
	
			// Items
			var items = {};
			_.each(jobs, function(job, index){
				
				var idx = index%columns;
				
				if(typeof items[idx] === 'undefined'){
					items[idx] = [];
				}
				
				var el = (<JobItem env_info={self.props.env_info} env={self.props.env} job={job} />);
				
				items[idx].push(el);
				
			});
			
			// Columns
			var cols = [];
			for(var i=0; i<columns; i++){
				var col = (<td>
		   			<ul className="job-list">{items[i]}</ul>
		   		</td>);
		   		
		   		cols.push(col);	
			}
		}
		
		table_classes += " cols_"+cols.length;
		
		return (
	   		<div className="job-list-container" style={contStyle}>
	   			<table cellPadding="0px" cellSpacing="0px" className={table_classes}>
	   				<tr>
	   					{cols}
	   				</tr>
	   			</table>
	   			<div className="clear"></div>
		 	</div>
	 	);
   		
	}
});
